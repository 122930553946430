import { useState, useEffect } from 'react';

export function useUrlChangeListener() {
  const [url, setUrl] = useState(window.location.search);

  useEffect(() => {
    const handlePopState = () => {
      setUrl(window.location.search);
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return url;
}