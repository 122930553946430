import { useEffect, useState } from 'react';
import { collectionInfoById } from '../../shopify/index';
const ShowcasePlaceholder = require('./ShowcasePlaceholder.png');

const ShowcaseThumbnail = ({
  details,
  merchantPage,
  liveNow,
  onClick,
  staging
}) => {
  const [collectionInfo, setCollectionInfo] = useState(null);
  useEffect(() => {
    getCollectionInfo();
  }, []);
  const getCollectionInfo = () => {
    if (details.defaultCollection) {
      collectionInfoById(details?.defaultCollection, staging).then(data => {
        setCollectionInfo(data);
      });
    }
  };

  const replaceFallbackImage = (ev) => {
    ev.target.src = ShowcasePlaceholder
  }

  const clickHere = () => {
    if (onClick) onClick();
  }

  const resolveAvatar = collectionInfo?.image || details?.avatar || ShowcasePlaceholder;

  if (resolveAvatar && details?.startDate && collectionInfo?.title) {
    return (
      <div style={{ borderRadius: '14px', overflow: 'hidden', height: '588px', width: '322px', cursor: 'pointer' }} onClick={clickHere}>
        <img src={resolveAvatar} style={{ resizeMode: 'cover', width: '100%', height: '100%' }} onError={replaceFallbackImage} />
        {merchantPage &&
          <div style={{ position: 'absolute', top: '70px', height: '60px', width: '322px' }}>
            <div style={{
              width: '280px',
              height: '25px',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
              textAlign: 'center',
              marginLeft: '20px',
              color: liveNow ? 'red' : '#E8701C',
            }}>
              {liveNow ? 'LIVE' : 'UPCOMING SHOWCASE'}
            </div>
          </div>}
        <div style={{ position: 'absolute', bottom: '56px', height: '60px', width: '322px' }}>
          <div style={{
            width: '280px',
            height: '25px',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
            textAlign: 'left',
            marginLeft: '20px',
            color: resolveAvatar ? 'white' : 'black',
          }}>
          </div>
          <div style={{
            width: '280px',
            height: '25px',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '21px',
            textAlign: 'left',
            marginLeft: '20px',
            color: resolveAvatar ? 'white' : 'black',
          }}>
            {collectionInfo?.title}
          </div>
        </div>
      </div >
    )
  }

  return null;
};

export default ShowcaseThumbnail;
