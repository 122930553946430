import React, { useEffect, useRef, useState } from 'react';
import './UpcomingCarousel.scss';

const UpcomingCarousel = ({ children, forcedIndex, onClick }) => {
  const scrollRef = useRef(null);
  const itemRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(forcedIndex);
  const [length, setLength] = useState(children.length);

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length);
  }, [children]);

  useEffect(() => {
    centerItem(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (forcedIndex || forcedIndex === 0) {
      setCurrentIndex(Number(forcedIndex));
    }
  }, [forcedIndex]);

  const centerItem = (index, right) => {
    const scrollContainer = scrollRef.current;
    const selectedItem = itemRefs.current[index];

    if (selectedItem && scrollContainer) {
      const scrollContainerWidth = scrollContainer.offsetWidth;
      const selectedItemWidth = selectedItem.offsetWidth;
      const selectedItemOffsetLeft = selectedItem.offsetLeft;


      // Calculate new scroll position to center the item
      const scrollLeft = selectedItemOffsetLeft + (selectedItemWidth / 2) - (scrollContainerWidth / 2);
      const direction = scrollLeft > scrollContainer.scrollLeft ? -15 : 15;
      scrollContainer.scrollLeft = scrollLeft + direction;
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      onClick(currentIndex - 1);
    } else {
      onClick(length - 1);
    }
  };

  const next = () => {
    if (currentIndex < (length - 1)) {
      onClick(currentIndex + 1);
    } else {
      onClick(0);
    }
  };

  return (
    <div className="comp-1k-wdg-upcoming-carousel-container">
      {children.length > 1 && <div className="comp-1k-wdg-upcoming-carousel-controls">
        <button onClick={prev} className="comp-1k-wdg-left-arrow">
          ←
        </button>
        <button onClick={next} className="comp-1k-wdg-right-arrow">
          →
        </button>
      </div>}
      <div className="comp-1k-wdg-upcoming-carousel-content" ref={scrollRef}>
        {React.Children.map(children, (child, index) => (
          <div className="comp-1k-wdg-slide" ref={el => itemRefs.current[index] = el}>
            {child}
          </div>
        ))}
      </div>

    </div>
  );
};

export default UpcomingCarousel;
