import { gql } from '@apollo/client';

export const REPLAY_QUERY = gql`
  query getVideos($input: VideoInput!) {
    videos(input: $input) {
      pagination {
        page
        rowsPerPage
        total
      }
      showcases {
        id
        eventId
        userEmail
        defaultCollection
        published
        startDate
        endDate
        avatar
        videos {
          id
          published
          url
        }
      }
      error
      messages
    }
  }
`;