import "./ChatMessage.scss";

const ChatMessage = ({ details, guestName }) => {
  const isMine = details?.displayName === guestName;

  return (
    <div className='comp-1k-wdg-message-container'>
      <div className={isMine ? "comp-1k-wdg-message-bubble comp-1k-wdg-message-bubble-own" : "comp-1k-wdg-message-bubble"}>
        <span className='comp-1k-wdg-message-author'>{details?.displayName}</span>
        <span className={"comp-1k-wdg-message"}>{details?.message}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
