import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { getShowcaseChatToken } from "../../queries/chat";
import { sanitize } from "../../utils/sanitize";
import { ChatRoom, SendMessageRequest } from "amazon-ivs-chat-messaging";
import ChatMessage from "../ChatMessage/ChatMessage";
import arrow from "../../assets/arrow.svg";
import share from "../../assets/share.svg";
import chatOn from "../../assets/chat-on.svg";
import chatOff from "../../assets/chat-off.svg";

import "./Chat.scss";
import Modal from "../Modal/Modal";

const Chat = ({ showcaseId, toggleVolume, handleChatReady, muted }) => {
  const [newMessage, setNewMessage] = useState("");
  const [chatRoom, setChatRoom] = useState();
  const [messages, setMessages] = useState([]);
  const [token, setToken] = useState();
  const [tempGuestName, setTempGuestName] = useState("");
  const [guestName, setGuestName] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState(true);
  const [chatEnabled, setChatEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [fetchTokenRequest, { data }] = useLazyQuery(getShowcaseChatToken, {
    fetchPolicy: "network-only",
  });

  const tokenProvider = async () => {
    return token;
  };

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (data?.getShowcaseChatToken?.token) {
      setToken({
        token: data?.getShowcaseChatToken?.token,
        sessionExpirationTime: new Date(data?.getShowcaseChatToken?.sessionExpirationTime),
        tokenExpirationTime: new Date(data?.getShowcaseChatToken?.tokenExpirationTime),
      });
    }
  }, [data]);

  useEffect(() => {
    if (chatRoom || !showcaseId) {
      return;
    }
    fetchTokenRequest({
      variables: {
        input: {
          id: showcaseId,
        },
        guestChatName: guestName || null,
      },
    });
    handleChatReady();
    return () => {
      setToken(undefined);
      setChatRoom(undefined);
    };
  }, [guestName]);

  useEffect(() => {
    if (token) {
      const room = new ChatRoom({
        regionOrUrl: "us-east-1",
        tokenProvider: () => tokenProvider(),
      });
      setChatRoom(room);
      room.connect();
    }
  }, [token]);

  useEffect(() => {
    if (!chatRoom?.addListener) {
      return;
    }

    const unsubscribeOnMessageReceived = chatRoom.addListener("message", (message) => {
      handleMessage(message);
    });

    return () => {
      chatRoom.disconnect();
      unsubscribeOnMessageReceived();
    };
  }, [chatRoom]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleMessage = (data) => {
    const displayName = data.sender?.attributes?.displayName;
    const userId = data.sender.userId;
    const avatar = data.sender?.attributes?.avatar;
    const message = sanitize(data.content);
    const messageId = data.id;
    const timestamp = data.sendTime;

    const newMessage = {
      timestamp,
      displayName,
      userId,
      avatar,
      message,
      messageId,
    };

    setMessages((prevState) => [...prevState, newMessage]);
  };

  const sendMessage = async (message) => {
    const content = `${message.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}`;
    const request = new SendMessageRequest(content);
    try {
      await chatRoom?.sendMessage(request);
    } catch (error) {
      // handleError(error);
    } finally {
      setNewMessage("");
    }
  };

  const onSendMessage = () => {
    sendMessage(newMessage);
  };

  const onSelectName = () => {
    setGuestName(tempGuestName);
    setTempGuestName("");
  };

  const onChangeNewMessage = (e) => {
    setNewMessage(e.target.value);
  };

  const onChangeGuestName = (e) => {
    setChatRoom(undefined);
    setTempGuestName(e.target.value);
  };

  const toggleActiveSpeaker = () => {
    setActiveSpeaker(!activeSpeaker);
    toggleVolume && toggleVolume(activeSpeaker ? 0 : 1);
  };

  const toggleEnableChat = () => {
    setChatEnabled(!chatEnabled);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href.toString());
    setShowModal(true)
  };

  const closeModal = () => {
    setShowModal(false);
  }

  const speakerOff = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/></svg>
  const speakerOn = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/></svg>


  return (
    <>
      <div className='chat-empty-header'></div>
      <div className='chat-window-container'>
        <div
          className='chat-messages-container'
          ref={messagesContainerRef}
        >
          {chatEnabled &&
            messages.map((message, idx) => (
              <ChatMessage
                key={`chat-message-${message.messageId}-${idx}`}
                details={message}
                guestName={guestName}
              />
            ))}
        </div>
        <div className='buttons-container'>
          <div
            className='side-button-container'
            onClick={handleShare}
          >
            <img
              className='side-button'
              src={share}
              alt='select'
            />
          </div>
          <div
            className='side-button-container'
            onClick={toggleActiveSpeaker}
          >
             <div className='side-button'>
              {!muted ? speakerOn : speakerOff}
            </div>
          </div>

          {
            <div
              className='side-button-container'
              onClick={toggleEnableChat}
            >
              <img
                className='side-button'
                src={chatEnabled ? chatOff : chatOn}
                alt='select'
              />
            </div>
          }
        </div>
        <div className='actions-container'>
          {chatEnabled &&
            (guestName ? (
              <div className='new-message-container'>
                <div className="input-container">
                <input
                  className='message-input'
                  type='text'
                  placeholder='Join the conversation...'
                  value={newMessage}
                  onChange={onChangeNewMessage}
                />
                </div>
                <div className='submit-button-container' onClick={onSendMessage}>
                  
                    <img
                      className="side-button"
                      src={arrow}
                      alt='select'
                    />
                </div>
              </div>
            ) : (
              <div className='name-container'>
                <div className="input-container">

                <input
                  className='message-input'
                  type='text'
                  placeholder='Pick a name to join'
                  minLength='4'
                  maxLength='15'
                  value={tempGuestName}
                  onChange={onChangeGuestName}
                />
                </div>

                <div className='submit-button-container' onClick={onSelectName}>
                  
                    <img
                      src={arrow}
                      alt='select'
                      className="side-button"
                    />
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal show={showModal} handleClose={closeModal}>
        <p className="modal-message">Share the link to this stream!</p>
        <p className="modal-message">(it's now in your clipboard)</p>
      </Modal>
    </>
  );
};

export default Chat;
