import './Marquee.scss';

const Marquee = () => {
  return (

    <div className="comp-1k-wdg-marquee">
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
      <span>
        <span className="comp-1k-wdg-upcoming">upcoming</span>
        <span className="comp-1k-wdg-showcases">showcases</span>
      </span>
    </div>
  );
};

export default Marquee;