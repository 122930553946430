import './index.css';
import Merchant from './sections/Merchant/Merchant';
import Replay from './sections/Replay/Replay';
import Stream from './sections/Stream/Stream';
import Trending from './sections/Trending/Trending';
import Upcoming from './sections/Upcoming/Upcoming';

const App: React.FC<{
  section: string,
  merchantEmail: string
  id: string
  staging: string
}> = ({
  section = null,
  merchantEmail = null,
  id = null,
  staging = null
}) => {
    switch (section) {
      case 'stream':
        return (<Stream staging={staging} />);
      case 'replay':
        return (<Replay id={id} staging={staging} />);
      case 'merchant':
        if (merchantEmail) {
          return (<Merchant
            // will be passed through props
            // merchantEmail={'brian+mer@maxplanck.dev'} // staging
            // merchantEmail={'sales@pisqueya.com'} // production
            merchantEmail={merchantEmail}
            staging={staging}
          />);
        }
        return null;
      case 'upcoming':
        return (<Upcoming staging={staging} />);
      case 'trending':
        return (<Trending staging={staging} />);
    }

    return (
      <div>No section selected</div>
    );
  }

export default App;
