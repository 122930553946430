import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import { collectionInfoById } from '../../shopify/index';
import './ReplayThumbnail.scss';


const showcase_placeholder = require('./showcase_placeholder.png');

const ReplayThumbnail = ({
  details,
  onClick,
  handleFullView,
  focused,
  isMerchant,
  staging
}) => {
  const [ready, setReady] = useState(false);
  const [muted, setMuted] = useState(true);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [playing, setPlaying] = useState(true);
  const playerRef = useRef(null);
  const downloadedTime = useRef(0);
  const hlsPlayer = useRef(null);

  const isIOS = /(iphone|ipod)/i.test(window.navigator.userAgent);

  const handleHLSLoad = (player) => {
    hlsPlayer.current = player.getInternalPlayer('hls');

    // Listen to FRAG_LOADED events to track downloaded time
    hlsPlayer.current.on(window.Hls.Events.FRAG_LOADED, (event, data) => {
      downloadedTime.current += data.frag.duration;
      if (downloadedTime.current >= 25) {
        // We stop loading after getting a reasonable amount of data 
        // (buffer are 10 secs and first is overhead, and we need a bit of the next one for the buffer)
        // so 3 frags should be enough
        hlsPlayer.current.stopLoad();
      }

    });
  };


  useEffect(() => {
    if (!ready) {
      setReady(true)
    }
    if (focused || isMerchant) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }, [focused])
  useEffect(() => {
    getCollectionInfo();
  }, []);

  // Duplicated reset after 15seconds check to ensure broader compatibility
  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const playedSeconds = playerRef.current.getCurrentTime();
        if (playedSeconds >= 15) {
          playerRef.current.seekTo(0);
          setPlaying(true);
        }
      }
    }, 1000);
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);


  const getCollectionInfo = () => {
    if (details.defaultCollection) {
      collectionInfoById(details?.defaultCollection, staging).then(data => {
        setCollectionInfo(data);
      });
    }
  };

  const handleProgress = ({ playedSeconds }) => {
    // If playedSeconds exceeds 15 seconds, seek back to the beginning
    if (playedSeconds >= 15) {
      playerRef.current.seekTo(0);
      setPlaying(true);
    }
  };

  const [video] = details?.videos;

  const toggleMuted = (e) => {
    e.stopPropagation();
    if (muted) {
      setPlaying(true);
    }
    setMuted(!muted);
  }

  const showFullView = (e) => {
    e.stopPropagation();
    setMuted(true);
    handleFullView();
  }

  // To avoid native pause/unpause
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  const speakerOff = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none" /><path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" /></svg>
  const speakerOn = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" /></svg>
  const openFull = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><rect fill="none" height="24" width="24" /><polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" /></svg>

  if (video) {
    return (
      <div
        onClick={focused ? toggleMuted : onClick}
        style={{
          cursor: 'pointer',
          height: '100%',
          borderRadius: '14px',
          overflow: 'hidden',
        }}>
        {isIOS && collectionInfo?.image ? (
          <div className="comp-1k-wdg-poster-container" onClick={showFullView}>
            <img src={collectionInfo?.image} alt="Video Poster" />
          </div>
        ) :
        <ReactPlayer
          url={video.url}
          ref={playerRef}
          pip={false}
          playing={playing}
          onReady={handleHLSLoad}
          playsinline={true}
          muted={muted}
          forcehls={'true'}
          width={'auto'}
          height={'100%'}
          onProgress={handleProgress}
          config={{
            file: {
              forceHLS: true,
              attributes: {
                playsInline: true,
              },
            },
            hlsOptions: {
              onLoad: handleHLSLoad,
              capLevelToPlayerSize: true,
              startLevel: 1,
            }
          }}
        />}
        <div
          onClick={handleOverlayClick}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            zindex: 500
          }}
        />

        {!isIOS && (focused || isMerchant) && <div style={{ position: 'absolute', top: (focused && !isMerchant) ? '20px' : '50px', height: '60px', width: '322px', zIndex: 1001 }} className="comp-1k-wdg-actions-container" >
          <div className="comp-1k-wdg-action-button-container" onClick={toggleMuted}>
            <div className='comp-1k-wdg-action-button'>
              {!muted ? speakerOn : speakerOff}
            </div>
          </div>
          <div className="comp-1k-wdg-action-button-container" onClick={showFullView}>
            <div className='comp-1k-wdg-action-button'>
              {openFull}
            </div>
          </div>
        </div>}
        <div style={{ position: 'absolute', bottom: (focused && !isMerchant) ? '16px' : '56px', height: '25px', width: '322px', zIndex: 1001 }}>

          <div style={{
            width: '280px',
            height: '25px',
            fontFamily: 'Poppins',
            fontSize: focused ? '16px' : '14px',
            fontWeight: 500,
            lineHeight: '21px',
            textAlign: 'left',
            marginLeft: '20px',
            color: '#ffffff',
          }}>
            {collectionInfo?.title}
          </div>

        </div>
      </div>
    )
  }

  return (
    <div>
      <img src={showcase_placeholder} alt="Slide 1" />
    </div>
  )
};

export default ReplayThumbnail;
