export function updateUrlParams(key, value, history) {
  const params = new URLSearchParams(window.location.search);

  // Set new or update existing parameter
  if (!value) {
    params.delete(key);
  } else {
    params.set(key, value);
  }

  // Create the new URL
  const newUrl = `${window.location.pathname}?${params.toString()}`;

  // Optionally update the browser history
  if (history) {
    history.push(newUrl);
  } else {
    // window.history.replaceState({}, '', newUrl);
    window.history.pushState({}, '', newUrl);
    // Manually trigger the popstate event to update components
    window.dispatchEvent(new PopStateEvent('popstate'));
  }
}
