import { gql } from '@apollo/client';

export const SHOWCASE_QUERY = gql`
  query getShowcase($id: String!) {
    getShowcase(id: $id) {
      defaultCollection
      error
      messages
    }
  }
`;