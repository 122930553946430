import { ApolloProvider } from '@apollo/client';
import React from 'react';
import App from './App';
import { client, stagingClient } from './client/apolloClient';

import r2wc from "@r2wc/react-to-web-component";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

const WrappedApp: React.FC<{
  section: string,
  merchant: string,
  id: string,
  staging: string,
}> = ({
  section,
  merchant,
  id,
  staging = ''
}) => {
    console.log('staging', staging)
    console.log('stagingClient', stagingClient)
    console.log('client', client)
    const apolloClient = staging ? stagingClient : client;
    return (
      <React.StrictMode>
        <ApolloProvider client={apolloClient}>
          <App section={section} merchantEmail={merchant} id={id} staging={staging} />
        </ApolloProvider>
      </React.StrictMode>
    )
  }

// root.render(
//   WrappedApp
// );

customElements.define(
  'onekin-widget',
  r2wc(
    WrappedApp,
    {
      props:
      {
        section: 'string',
        merchant: 'string',
        id: 'string',
        staging: 'string'
      }
    }
  )
);