
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ReplayThumbnail from '../../components/ReplayThumbnail/ReplayThumbnail';
import Carousel from '../../components/TrendingCarousel/Carousel';
import { TRENDING_QUERY } from '../../queries/trending';
import { updateUrlParams } from '../../utils/urlParams';
import './Trending.scss';

const Trending = ({ staging }) => {
  const { data } = useQuery(TRENDING_QUERY, {
    fetchPolicy: 'network-only' // or 'no-cache'
  });
  const [forcedIndex, setForcedIndex] = useState(1);

  useEffect(() => {
    if (window.innerWidth < 630) {
      setForcedIndex(0);
      setTimeout(() => {
        setForcedIndex(1);
      }, 1000);
    }
  }, []);

  const joinIcymi = (showcases, videos) => {
    return showcases.map((showcase, idx) => {
      return { ...showcase, videos: [videos[idx]] };
    });
  };

  const icymiVideos = joinIcymi(
    data?.landingPageV3.icymiShowcases || [],
    data?.landingPageV3.icymiVideos || [],
  ).slice(0, 5) || [];

  const forceIndex = (idx, item) => {
    setForcedIndex(idx);
  }

  const handleFullView = (item) => {
    if (item?.videos[0]?.id) {
      updateUrlParams('videoId', item.videos[0].id);
    }
  }

  return (
    <div className="trending-1k-wgt-container">
      <div className="trending-1k-wgt-intro">
        <div className="trending-1k-wgt-header">
          watch&nbsp;&&nbsp;shop
        </div>
        <div className="trending-1k-wgt-title">
          trending video showcases
        </div>
        <div className="trending-1k-wgt-description">
          <p>
            Hear brand stories, watch product demos, get lifestyle tips, and engage with a community of shoppers just like you.
          </p>
          <p>
            Download the oneKIN Live app for
            the full immersive experience.
          </p>
        </div>
        {/* <div className="trending-1k-wgt-action">
          VIEW ALL VIDEOS
        </div> */}
      </div>
      <div className="trending-1k-wgt-content">
        <Carousel forcedIndex={forcedIndex} onClick={(idx) => forceIndex(idx)}>
          {icymiVideos?.map((item, idx) => (
            <ReplayThumbnail
              staging={staging}
              key={`trending-video-${item.id}-${idx}`}
              focused={forcedIndex === idx}
              onClick={() => forceIndex(idx, item)}
              details={item}
              lastItem={false}
              handleFullView={() => handleFullView(item)}
            />)
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Trending;