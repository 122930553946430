
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import MerchantCarousel from '../../components/MerchantCarousel/MerchantCarousel';
import ReplayThumbnail from '../../components/ReplayThumbnail/ReplayThumbnail';
import ShowcaseThumbnail from '../../components/ShowcaseThumbnail/ShowcaseThumbnail';
import { LIVE_QUERY } from '../../queries/live';
import { MERCHANT_QUERY } from '../../queries/merchant';
import { updateUrlParams } from '../../utils/urlParams';
import './Merchant.scss';

const Merchant = ({ merchantEmail, staging }) => {
  const { loadingLiveShowcase, errorLiveShowcase, data: dataLiveShowcase } = useQuery(LIVE_QUERY, {
    fetchPolicy: 'network-only' // or 'no-cache'
  });
  const [liveShowcases, setLiveShowcases] = useState(null);

  useEffect(() => {
    if (dataLiveShowcase) {
      const liveShowcases = dataLiveShowcase.landingPageV3?.currentEvents?.reduce((acc, curr) => {
        const newAcc = [...acc];
        const liveShowcases =
          curr?.showcases?.filter(
            (showcase) => showcase.status === 'LIVE' && showcase.userEmail === merchantEmail,
          ) || [];
        return [...newAcc, ...liveShowcases];
      }, []) || [];
      setLiveShowcases(liveShowcases);
    }
  }, [dataLiveShowcase])

  const [forcedIndex, setForcedIndex] = useState(0)
  const [showcases, setShowcases] = useState(null)
  const [upcomingShowcase, setUpcomingShowcase] = useState(null)
  const { loading, error, data } = useQuery(MERCHANT_QUERY, {
    variables: {
      input: {
        email: merchantEmail,
        page: 0,
      }
    },
    skip: !merchantEmail,
  }, {
    fetchPolicy: 'network-only' // or 'no-cache'
  });

  const joinIcymi = (showcases, videos) => {
    return showcases.map((showcase, idx) => {
      return { ...showcase, videos: [videos[idx]] };
    });
  };

  useEffect(() => {
    if (data) {
      const showcases = joinIcymi(
        data?.getMerchantInfoByUserEmail?.icymiShowcases || [],
        data?.getMerchantInfoByUserEmail?.icymiVideos || [],
      );

      setShowcases(showcases);
      if (data?.getMerchantInfoByUserEmail?.nextShowcase) {
        const liveNow =
          data?.getMerchantInfoByUserEmail?.nextShowcase?.startDate &&
            new Date(data?.getMerchantInfoByUserEmail?.nextShowcase?.startDate) <= new Date()
            ? true
            : false;
        const upcomingShowcase = {
          avatar: data?.getMerchantInfoByUserEmail?.avatar,
          id: data?.getMerchantInfoByUserEmail?.nextShowcase?.id,
          startDate: data?.getMerchantInfoByUserEmail?.nextShowcase?.startDate,
          // maybe it is not this one for the next showcase?
          // but this would have to change from the BE call.
          defaultCollection: data?.getMerchantInfoByUserEmail?.defaultCollection,
        }
        setUpcomingShowcase(upcomingShowcase);
      }
      // setShowcases([...showcases, ...showcases, ...showcases, ...showcases]);
    }
  }, [data]);

  const handleFullView = (item) => {
    if (item?.videos[0]?.id) {
      updateUrlParams('videoId', item.videos[0].id);
    }
  }

  const forceIndex = (idx) => {
    setForcedIndex(idx);
  }

  const showLive = () => {
    updateUrlParams('showcaseId', upcomingShowcase.id);
  }

  const focusAndPlay = (idx, icymi) => {
    forceIndex(idx + 1)
    updateUrlParams('videoId', icymi.videos[0].id);
  }

  if (!showcases) return null;

  let children = []

  if (upcomingShowcase) {
    const isLive = liveShowcases?.find((showcase) => showcase.id === upcomingShowcase.id) ? true : false;
    children.push(<ShowcaseThumbnail
      staging={staging}
      merchantPage={true}
      liveNow={isLive}
      key={`trending-video-upcoming-showcase`}
      focused={false}
      onClick={() => {
        if (isLive) {
          showLive(upcomingShowcase)
        } else {
          forceIndex(0)
        }
      }}
      details={upcomingShowcase}
      lastItem={false}
    />)
  }

  children = [...children, ...(showcases?.map((item, idx) => (
    <ReplayThumbnail
      key={`trending-video-${item.id}-${idx}`}
      focused={false}
      onClick={() => focusAndPlay(idx, item)}
      details={item}
      isMerchant={true}
      lastItem={false}
      handleFullView={() => handleFullView(item)}
      staging={staging}
    />)
  )) || []]

  return (
    <div className="merchant-1k-wgt-showcases-container">
      <div>
        <MerchantCarousel forcedIndex={forcedIndex} onClick={(idx) => forceIndex(idx)}>
          {children}
        </MerchantCarousel>
      </div>
    </div>
  );
};

export default Merchant;