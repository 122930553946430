import { gql } from '@apollo/client';

export const getShowcaseChatToken = gql`
  query getShowcaseChatToken($input: StartShowcaseInput!, $guestChatName: String) {
    getShowcaseChatToken(input: $input, guestChatName: $guestChatName) {
      sessionExpirationTime
      token
      tokenExpirationTime
      error
      messages
    }
  }
`;

