import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/file';
import Chat from '../../components/Chat/Chat';
import { SHOWCASE_QUERY } from '../../queries/showcase';
import { STREAM_QUERY } from '../../queries/stream';
import { collectionInfoById } from '../../shopify/index';
import { updateUrlParams } from '../../utils/urlParams';
import { useUrlChangeListener } from '../../utils/useUrlChangeListener';
import './Stream.scss';
const StreamPlaceholder = require('./StreamPlaceholder.png');



const Stream = ({ staging }) => {
  const currentUrl = useUrlChangeListener();
  const [showcaseId, setShowcaseId] = useState(null);
  const [streamInfo, setStreamInfo] = useState(null);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [ready, setReady] = useState(false)
  const [volume, setVolume] = useState(1);
  const [chatReady, setChatReady] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(currentUrl)
    if (params.has('showcaseId')) {
      setShowcaseId(params.get('showcaseId'))
    }
  }, [currentUrl])

  useEffect(() => {
    if (showcaseId) {
      fetchAllInfo();
    }
  }, [showcaseId])

  const { refetch: getStreamInfo } = useQuery(STREAM_QUERY, {
    variables: {
      input: {
        id: showcaseId
      }
    },
    skip: !showcaseId,
  }, { fetchPolicy: 'network-only' },);

  const { refetch: getShowcaseInfo } = useQuery(SHOWCASE_QUERY, {
    variables: {
      id: showcaseId
    },
    skip: !showcaseId,
  }, { fetchPolicy: 'network-only' },);

  const fetchAllInfo = async () => {
    await fetchStreamInfo();
    const showcaseInfo = await getShowcaseInfo();
    if (showcaseInfo.data.getShowcase.defaultCollection) {
      getCollectionInfo(showcaseInfo.data.getShowcase.defaultCollection, staging)
    }
  }

  const fetchStreamInfo = async () => {
    const streamInfo = await getStreamInfo();
    setStreamInfo({ ...streamInfo.data.getShowcaseStreamUrl });
  }

  const getCollectionInfo = (collectionId, staging) => {
    collectionInfoById(collectionId, staging).then(data => {
      setCollectionInfo(data);
    });
  };

  useEffect(() => {
    if (showcaseId && collectionInfo && streamInfo && streamInfo && !ready) {
      if (streamInfo.streamUrl && chatReady) {
        setReady(true)
      } else {
        setTimeout(fetchStreamInfo, 1000)
      }
    }
  }, [showcaseId, collectionInfo, streamInfo, ready])

  const closeVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateUrlParams('showcaseId', '');
    setStreamInfo(null);
    setCollectionInfo(null);
    setReady(false);
  }

  const navigateToLink = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  }

  const restartPlayer = () => {
    setReady(false);
    setTimeout(() => {
      setReady(true);
    }, 1000)
  }

  const toggleVolume = (volume) => {
    setVolume(volume);
  }

  const handleChatReady = () => {
    setChatReady(true);
  }

  if (showcaseId && streamInfo) {

    if (!streamInfo || !collectionInfo) {
      return null;
    }

    const info = { ...streamInfo };
    const resolveAvatar = collectionInfo?.image || info?.avatar || StreamPlaceholder;
    const image = collectionInfo?.products?.[0]?.image;
    const title = collectionInfo?.products?.[0]?.title;
    const compareAtPrice = collectionInfo?.products?.[0]?.compareAtPrice;
    const price = collectionInfo?.products?.[0]?.price;
    const link = collectionInfo?.onlineStoreUrl || collectionInfo?.url;
    return (
      <div className='stream-1k-wgt-stream-overlay-container'>
        <div className='stream-1k-wgt-stream-container'>
          <div className='stream-1k-wgt-video-container'>
            {streamInfo.streamUrl && <div className='stream-1k-wgt-live-indicator'>LIVE</div>}
            {!streamInfo.streamUrl && <div className='stream-1k-wgt-standby-indicator'>ABOUT TO START</div>}
            <div className='stream-1k-wgt-collection-info'>
              <div className='stream-1k-wgt-collection-image'>
                <img src={resolveAvatar} alt='avatar' />
              </div>
              <div className='stream-1k-wgt-collection-name'>
                {collectionInfo?.title}
              </div>
            </div>
            <div className='stream-1k-wgt-close-button' onClick={closeVideo}>
              +
            </div>
            {image && (
              <div className='stream-1k-wgt-product-container' onClick={() => navigateToLink(link)}>
                <div className='stream-1k-wgt-product-description'>
                  <div className='stream-1k-wgt-product-image-container'>
                    <img src={image} alt={title} />
                  </div>
                  <div className='stream-1k-wgt-product-details'>
                    <div>
                      <div className='stream-1k-wgt-product-title'>
                        {title.slice(0, 12) + (title?.length > 12 ? '...' : '')}
                      </div>
                      <div className='stream-1k-wgt-product-price'>
                        {!!compareAtPrice && compareAtPrice !== price && (
                          <span className='stream-1k-wgt-product-old-price'>
                            ${Number(compareAtPrice).toFixed(2)}
                          </span>
                        )}
                        < span >
                          ${Number(price).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>)
            }
            {streamInfo.streamUrl && ready ? (
              <ReactPlayer
                onError={restartPlayer}
                controls={false}
                url={streamInfo.streamUrl}
                playing={true}
                pip={false}
                // muted={muted}
                forcehls={'true'}
                width={'100%'}
                height={'100%'}
                volume={volume}
              />
            ) : (
              <div style={{ height: '100%' }}>
                <div style={{
                  backgroundImage: `url(${resolveAvatar})`,
                  // backgroundImage: `url(${StreamPlaceholder})`,
                  position: 'absolute',
                  zIndex: 99,
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',

                }} />
                <img src={resolveAvatar} height="100%" width="100%" />
              </div>
            )}
            <div className='stream-1k-wgt-chat-container'>
              {streamInfo?.chatArn &&
                <Chat showcaseId={streamInfo.id} toggleVolume={toggleVolume} handleChatReady={handleChatReady} muted={volume == 0} />}
            </div>
          </div>
        </div>
      </div >
    );
  }

  return null
}

export default Stream;