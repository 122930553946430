import { GraphQLClient, gql } from 'graphql-request';

const SHOPIFY_STOREFRONT_API = process.env.REACT_APP_SHOPIFY_STOREFRONT_API || '';
const SHOPIFY_STOREFRONT_TOKEN = process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN || '';

const SHOPIFY_STAGING_STOREFRONT_API = process.env.REACT_APP_STAGING_SHOPIFY_STOREFRONT_API || '';
const SHOPIFY_STAGING_STOREFRONT_TOKEN = process.env.REACT_APP_STAGING_SHOPIFY_STOREFRONT_TOKEN || '';

export const shopifyGraphQLClient = new GraphQLClient(SHOPIFY_STOREFRONT_API, {
  headers: {
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_TOKEN,
  },
});

export const shopifyStagingGraphQLClient = new GraphQLClient(SHOPIFY_STAGING_STOREFRONT_API, {
  headers: {
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STAGING_STOREFRONT_TOKEN,
  },
});

export const getCollectionInfoByIdQuery = (collectionId) => gql`
  {
    collection(id: "${collectionId}") {
      id
      title
      description
      onlineStoreUrl
      image {
        url
      }
      products(first: 1) {
        edges {
          node {
            title
            onlineStoreUrl
            featuredImage {
              url
            }
            variants(first: 1) {
              edges {
                node {
                  compareAtPrice {
                    amount
                  }
                  price { 
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getCollectionInfoByIdFromShopify = async (collectionId, staging = false) => {
  const query = getCollectionInfoByIdQuery(collectionId);
  const client = staging ? shopifyStagingGraphQLClient : shopifyGraphQLClient;
  const collectionCall = await client.request(query);

  return collectionCall;
};


export const collectionInfoById = async (collectionId, staging = false) => {
  try {
    const response = await getCollectionInfoByIdFromShopify(collectionId, staging && !!staging && staging !== '');
    const mappedResponse = {
      ...response.collection,
      image: response?.collection?.image?.url,
      url: response?.collection?.onlineStoreUrl,
      products:
        response?.collection?.products?.edges?.map(
          ({ node }) => {
            return {
              url: node?.onlineStoreUrl,
              title: node.title,
              image: node?.featuredImage?.url,
              price: node?.variants?.edges?.[0]?.node?.price?.amount,
              compareAtPrice: node?.variants?.edges?.[0]?.node?.compareAtPrice?.amount,
            };
          },
        ) || [],
    };
    return mappedResponse;
  } catch (err) {
    return null
  }
};