import { gql } from '@apollo/client';

export const STREAM_QUERY = gql`
  query getShowcaseStreamUrl($input: StartShowcaseInput!) {
    getShowcaseStreamUrl(input: $input) {
      id
      streamUrl
      chatArn
      startTime
      error
      messages
    }
  }
`;