import { gql } from '@apollo/client';

export const UPCOMING_QUERY = gql`
query landingPageV3 {
  landingPageV3 {
    currentEvents {
      id
      title
      description
      published
      startDate
      endDate
      image
      defaultCollection
      interests {
        id
        name
      }
      showcases {
        id
        eventId
        userEmail
        defaultCollection
        published
        startDate
        endDate
        avatar
        videos {
          url
          published
          id
        }
        status
      }
    }
  }
}
`;