import { gql } from '@apollo/client';

export const MERCHANT_QUERY = gql`
  query getMerchantInfoByUserEmail($input: MerchantInput!) {
    getMerchantInfoByUserEmail(input: $input) {
      id
      email
      displayName
      avatar
      defaultCollection

      icymiShowcases {
        id
        eventId
        userEmail
        defaultCollection
        published
        startDate
        endDate
        avatar
      }

      icymiVideos {
        id
        published
        url
      }

      nextShowcase {
        startDate
        id
      }

      error
      errorSource
      messages
    }
  }
`;