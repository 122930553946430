import { gql } from '@apollo/client';

export const TRENDING_QUERY = gql`
query landingPageV3 {
  landingPageV3 {
    icymiShowcases {
      id
      eventId
      userEmail
      defaultCollection
      published
      startDate
      endDate
      avatar
    }

    icymiVideos {
      id
      published
      url
    }
  }
}
`;
