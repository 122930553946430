import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_ONEKIN_BE_URL,
  cache: new InMemoryCache()
});

export const stagingClient = new ApolloClient({
  uri: process.env.REACT_APP_STAGING_ONEKIN_BE_URL,
  cache: new InMemoryCache()
});