import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/file';
import { REPLAY_QUERY } from '../../queries/replay';
import { collectionInfoById } from '../../shopify/index';
import { updateUrlParams } from '../../utils/urlParams';
import { useUrlChangeListener } from '../../utils/useUrlChangeListener';
import './Replay.scss';
const ReplayPlaceholder = require('./ReplayPlaceholder.png');

const Replay = ({ id, staging }) => {
  const currentUrl = useUrlChangeListener();
  const [videoId, setVideoId] = useState(id);
  const [data, setData] = useState(null);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const { data: replayVideoInfoData, refetch: getReplayVideoInfo } = useQuery(REPLAY_QUERY, {
    variables: {
      input: {
        id: videoId
      }
    },
    skip: !videoId,
  }, { fetchPolicy: 'network-only' },);

  useEffect(() => {
    initialize();
  }, [currentUrl, id])

  useEffect(() => {
    setAndPopulate(replayVideoInfoData)
  }, [replayVideoInfoData])

  const setAndPopulate = async (data) => {
    if (data?.videos?.pagination?.total === 1 && data?.videos?.showcases[0]?.defaultCollection) {
      setData(data);

      const collectionId = data?.videos?.showcases[0]?.defaultCollection;

      const collectionInfo = await collectionInfoById(collectionId, staging)
      setCollectionInfo(collectionInfo);
    }
  }

  const initialize = async () => {
    if (id) {
      await setVideoId(id);
      getReplayVideoInfo();
    } else {
      const params = new URLSearchParams(currentUrl)
      if (params.has('videoId')) {

        const videoId = params.get('videoId');
        if (videoId) {
          await setVideoId(videoId);
          getReplayVideoInfo();
        }
      }
    }
  }

  const closeVideo = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    updateUrlParams('videoId', '');
    setVideoId(null);
    setData(null);
  }

  const navigateToLink = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  }

  if (videoId && data?.videos?.pagination?.total === 1) {
    const info = data?.videos?.showcases[0];
    const resolveAvatar = collectionInfo?.image || info?.avatar || ReplayPlaceholder;
    const image = collectionInfo?.products?.[0]?.image;
    const title = collectionInfo?.products?.[0]?.title;
    const compareAtPrice = collectionInfo?.products?.[0]?.compareAtPrice;
    const price = collectionInfo?.products?.[0]?.price;
    const link = collectionInfo?.onlineStoreUrl || collectionInfo?.url;

    if (!(info || collectionInfo)) {
      return null;
    }

    return (
      <div className='replay-1k-wgt-replay-overlay-container'>
        <div className='replay-1k-wgt-replay-container'>
          <div className='replay-1k-wgt-video-container'>
            <div className='replay-1k-wgt-collection-info'>
              <div className='replay-1k-wgt-collection-image'>
                <img src={resolveAvatar} alt='avatar' />
              </div>
              <div className='replay-1k-wgt-collection-name'>
                {collectionInfo?.title}
              </div>
            </div>
            <div className='replay-1k-wgt-close-button' onClick={closeVideo}>
              +
            </div>
            {image && (
              <div className='replay-1k-wgt-product-container' onClick={() => navigateToLink(link)}>
                <div className='replay-1k-wgt-product-description'>
                  <div className='replay-1k-wgt-product-image-container'>
                    <img src={image} alt={title} />
                  </div>
                  <div className='replay-1k-wgt-product-details'>
                    <div>
                      <div className='replay-1k-wgt-product-title'>
                        {title.slice(0, 12) + (title?.length > 12 ? '...' : '')}
                      </div>
                      <div className='replay-1k-wgt-product-price'>
                        {!!compareAtPrice && compareAtPrice !== price && (
                          <span className='replay-1k-wgt-product-old-price'>
                            ${Number(compareAtPrice).toFixed(2)}
                          </span>
                        )}
                        < span >
                          ${Number(price).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>)
            }
            {info?.videos?.[0]?.url && (
              <ReactPlayer
                controls={true}
                url={info?.videos?.[0]?.url}
                playing={true}
                pip={false}
                // muted={muted}
                forcehls={'true'}
                width={'100%'}
                height={'100%'}
                onEnded={() => { }}
              />)}
          </div>
        </div>
      </div >
    );
  }

  return null
}

export default Replay;