
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import Marquee from '../../components/Marquee/Marquee';
import ShowcaseThumbnail from '../../components/ShowcaseThumbnail/ShowcaseThumbnail';
import UpcomingCarousel from '../../components/UpcomingCarousel/UpcomingCarousel';
import { UPCOMING_QUERY } from '../../queries/upcoming';
import { updateUrlParams } from '../../utils/urlParams';
import './Upcoming.scss';

const Upcoming = ({ staging }) => {
  const [forcedIndex, setForcedIndex] = useState(0)
  const [showcases, setShowcases] = useState(null)
  const { loading, error, data } = useQuery(UPCOMING_QUERY, {
    fetchPolicy: 'network-only' // or 'no-cache'
  });

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  useEffect(() => {
    if (data) {
      const showcases = data?.landingPageV3?.currentEvents.reduce((acc, curr) => {
        const newAcc = [...acc]
        if (curr.showcases) {
          newAcc.push(...curr.showcases)
        }
        return newAcc
      }, []) || [];

      const upcoming = showcases
        .filter(showcase => showcase?.status !== 'CONCLUDED') // remove concluded
        .sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate)); // sort from earliest to latest

      setShowcases(upcoming);
    }
  }, [data]);

  const forceIndex = (idx, id) => {
    setForcedIndex(idx);
    updateUrlParams('showcaseId', id);
  }



  if (!showcases) return null;

  return (
    <div className="upcoming-1k-wgt-showcases-container">
      <Marquee />
      <div>
        <UpcomingCarousel forcedIndex={forcedIndex} onClick={(idx) => forceIndex(idx)}>
          {showcases?.map((item, idx) => (
            <ShowcaseThumbnail
              staging={staging}
              key={`trending-video-${item.id}-${idx}`}
              focused={forcedIndex === idx}
              onClick={() => forceIndex(idx, item.id)}
              details={item}
              lastItem={false}
            />)
          )}
        </UpcomingCarousel>
      </div>
    </div>
  );
};

export default Upcoming;