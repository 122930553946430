// Escape special characters
export const sanitize = (text) => {
	const map = {
	  '&': '&amp;',
	  '<': '&lt;',
	  '>': '&gt;',
	  '"': '&quot;',
	  "'": "'",
	  '/': '&#x2F;',
	  '`': '&grave;',
	};
	const reg = /[&<>"'/]/gi;
	return text.replace(reg, match => map[match]);
  };
  